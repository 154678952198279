import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router'
import { paths } from '@pages/paths'
import { NavLink } from '@ui/link'
import { LogoIcon } from '@ui/icons/logo'
import { Devices } from '@styles'
import SocialFacebook from '@assets/icons/facebook.svg'
import SocialOk from '@assets/icons/ok.svg'
import SocialTelegram from '@assets/icons/telegram.svg'
import SocialInstagram from '@assets/icons/instagram.svg'
import SocialVk from '@assets/icons/vk.svg'
import SocialYoutube from '@assets/icons/youtube.svg'
import { isClientSide } from '@lib/ssr'
import { useDisabledBodyScroll } from '@lib/use-disabled-body-scroll'
import { useBackgroundOnKroll } from '@lib/use-on-krol'
import { LogoColors } from '@api/sliders'
import NavClosedIcon from './nav-closed.svg'
import NavOpenedIcon from './nav-opened.svg'

interface LayoutProps extends React.HTMLAttributes<HTMLElement> {
  showBorder?: boolean
  transparentMobileNav?: boolean
  hideDesktopNav?: boolean
  whiteNavigationFont?: boolean
  logoColor?: LogoColors
}

export const Layout = (props: LayoutProps) => {
  const [mobileNavIsOpened, setMobileNavIsOpened] = React.useState(false)
  const mobileHeaderBackgroundIsVisible = useBackgroundOnKroll()
  const [_, setBodyScrollIsDisabled] = useDisabledBodyScroll(false)

  const showBorder = props.showBorder ?? true

  const openMobileNav = () => setMobileNavIsOpened(true)
  const closeMobileNav = () => setMobileNavIsOpened(false)

  React.useEffect(() => {
    if (mobileNavIsOpened && isClientSide()) {
      setBodyScrollIsDisabled(true)
      return
    }

    setBodyScrollIsDisabled(false)
  }, [mobileNavIsOpened])

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <LayoutWrapper {...props}>
      <HeaderWrapper>
        <div style={{ position: 'fixed', fontFamily: 'Magnolia Script', color: 'transparent', userSelect: 'none' }}>
          .
        </div>
        <Header style={{ backgroundColor: showBorder ? 'white' : 'transparent' }} data-hide={props.hideDesktopNav}>
          <LogoWrapper>
            <LogoIcon color={props.logoColor} />
          </LogoWrapper>
          <Navigation whiteNavigationFont={props.whiteNavigationFont && !props.showBorder} />
        </Header>

        {mobileNavIsOpened ? (
          <OpenedMobileNavWrapper>
            <OpenedMobileNavHeader style={{ backgroundColor: mobileHeaderBackgroundIsVisible ? 'white' : '' }}>
              <MobileNavLogoWrapper>
                <LogoIcon />
              </MobileNavLogoWrapper>
            </OpenedMobileNavHeader>

            <MobileNavigation onClose={closeMobileNav} />
          </OpenedMobileNavWrapper>
        ) : (
          <ClosedMobileNavWrapper
            style={{ backgroundColor: mobileHeaderBackgroundIsVisible ? 'white' : '' }}
            data-transparent={props.transparentMobileNav}
          >
            <MobileNavLogoWrapper>
              <LogoIcon />
            </MobileNavLogoWrapper>
            <div onClick={openMobileNav}>
              <NavClosedIcon />
            </div>
          </ClosedMobileNavWrapper>
        )}
      </HeaderWrapper>

      <ContentWrapper>{props.children}</ContentWrapper>
    </LayoutWrapper>
  )
}

const LayoutWrapper = styled.div`
  font-family: 'Raleway', 'SansSerif';
  padding-top: 70px;

  @media screen and ${Devices.laptop} {
    padding-top: 80px;
  }

  @media screen and ${Devices.desktopM} {
    padding-top: 180px;
  }
`

const ContentWrapper = styled.main``

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  display: none;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
  height: 80px;

  &[data-hide='true'] {
    display: none !important;
  }

  @media screen and ${Devices.desktopM} {
    height: 180px;
  }
}
`

const ClosedMobileNavWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 15px 25px;

  width: 100vw;
  height: 70px;

  &[data-transparent='true'] {
    border: 1px solid transparent;
    background-color: transparent;
  }
`

const OpenedMobileNavWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100000;

  background-color: transparent;

  height: 100vh;
  width: 100vw;
`

const OpenedMobileNavHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 15px 25px;

  width: 100vw;
  height: 70px;
`

const HeaderWrapper = styled.div`
  @media screen and ${Devices.laptop} {
    > ${Header} {
      display: flex;
    }

    > ${ClosedMobileNavWrapper} {
      display: none;
    }
  }
`

const LogoWrapper = styled.div`
  position: fixed;
  top: 5px;
  left: 75px;

  @media screen and ${Devices.desktopM} {
    top: 50px;
  }

  svg {
    width: 117.43px;
    height: 70px;

    path {
      fill: #333333;
    }
  }
`

interface NavigationProps {
  whiteNavigationFont?: boolean
}

const Navigation = (props: NavigationProps) => {
  const location = useLocation()

  return (
    <NavigationWrapper>
      <NavLink active={location.pathname === paths.home()} href={paths.home()} whiteFont={props.whiteNavigationFont}>
        Главная
      </NavLink>
      <NavLink active={location.pathname === paths.about()} href={paths.about()} whiteFont={props.whiteNavigationFont}>
        Обо мне
      </NavLink>
      <NavLink active={location.pathname === paths.music()} href={paths.music()} whiteFont={props.whiteNavigationFont}>
        Музыка
      </NavLink>
      <NavLink
        active={location.pathname.startsWith(paths.photoVideo())}
        href={paths.photoVideo()}
        whiteFont={props.whiteNavigationFont}
      >
        Фото/Видео
      </NavLink>
      <NavLink active={location.pathname === paths.news()} href={paths.news()} whiteFont={props.whiteNavigationFont}>
        Новости
      </NavLink>
      <NavLink active={location.pathname === paths.live()} href={paths.live()} whiteFont={props.whiteNavigationFont}>
        LIVE
      </NavLink>
      {/*<NavLink href={paths.store()}>Store</NavLink>*/}
      <NavLink
        active={location.pathname === paths.contacts()}
        href={paths.contacts()}
        whiteFont={props.whiteNavigationFont}
      >
        Контакты
      </NavLink>
    </NavigationWrapper>
  )
}

interface MobileNavigationProps {
  onClose: () => void
}

const MobileNavigation = (props: MobileNavigationProps) => {
  const location = useLocation()

  return (
    <Nav>
      <div style={{ position: 'fixed', top: '25px', right: '30px' }} onClick={props.onClose}>
        <NavOpenedIcon />
      </div>

      <NavLink active={location.pathname === paths.home()} href={paths.home()}>
        Главная
      </NavLink>
      <NavLink active={location.pathname === paths.about()} href={paths.about()}>
        Обо мне
      </NavLink>
      <NavLink active={location.pathname === paths.music()} href={paths.music()}>
        Музыка
      </NavLink>
      <NavLink active={location.pathname === paths.photoVideo()} href={paths.photoVideo()}>
        Фото/Видео
      </NavLink>
      <NavLink active={location.pathname === paths.news()} href={paths.news()}>
        Новости
      </NavLink>
      <NavLink active={location.pathname === paths.live()} href={paths.live()}>
        LIVE
      </NavLink>
      {/*<NavLink href={paths.store()}>Store</NavLink>*/}
      <NavLink active={location.pathname === paths.contacts()} href={paths.contacts()}>
        Контакты
      </NavLink>

      <MobileSocialLinks />
    </Nav>
  )
}

const NavigationWrapper = styled.nav`
  display: flex;
  align-items: center;

  font-family: 'Magnolia Script', 'SansSerif';

  div:not(:last-child) {
    margin-right: 40px;
  }

  margin-right: 75px;
  margin-top: 5px;
`

const Nav = styled.nav`
  position: fixed;
  right: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 100px;

  margin-left: auto;

  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(15px);

  overflow: auto;
  height: 100vh;
  width: 60vw;

  @media screen and ${Devices.mobileM} {
    width: 50vw;
  }

  font-family: 'Magnolia Script', 'SansSerif';

  > * {
    margin-bottom: 15px;
  }
`

const MobileNavLogoWrapper = styled.div`
  width: 90px;
  height: 40px;

  svg {
    width: 90px;
    height: 40px;
  }
`

const MobileLink = styled.a`
  color: unset;
  text-decoration: none;
`

const MobileSocialLinksWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: max-content max-content;
  gap: 30px;

  margin-top: 20px;
`

const MobileSocialLinks = () => (
  <MobileSocialLinksWrapper>
    <MobileLink href='https://ok.ru/group/68622723514413' target='_blank'>
      <SocialOk />
    </MobileLink>
    <MobileLink href='https://t.me/chistovamusic' target='_blank'>
      <SocialTelegram />
    </MobileLink>
    <MobileLink href='https://vk.com/chistovagroup' target='_blank'>
      <SocialVk />
    </MobileLink>
    <MobileLink href='https://www.youtube.com/c/Chistovamusic' target='_blank'>
      <SocialYoutube />
    </MobileLink>
  </MobileSocialLinksWrapper>
)
