import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Underline from './underline.svg'

interface NavLinkProps extends React.HTMLAttributes<HTMLDivElement> {
  href: string
  active?: boolean
  whiteFont?: boolean
}

export const NavLink = (props: NavLinkProps) => (
  <LinkWrapper style={props.style} className={props.className} data-active={props.active}>
    <Link to={props.href} data-whitefont={props.whiteFont}>
      {props.children}
    </Link>
    <Underline />
  </LinkWrapper>
)

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 18px;
  font-weight: 400;
  line-height: 21px;

  position: relative;

  a {
    text-decoration: none;
    color: #333333;
    margin-bottom: 2px;
    user-select: none;

    &[data-whitefont='true'] {
      color: white;
    }
  }

  svg {
    position: absolute;
    bottom: -6px;

    path {
      fill: transparent;
    }
  }

  &:hover,
  &[data-active='true'] {
    a {
      color: #e469d1;
    }

    svg {
      path {
        fill: #e469d1;
      }
    }
  }
`
