import * as React from 'react'
import { isClientSide } from '@lib/ssr'

export function useBackgroundOnKroll(): boolean {
  const [showBackground, setShowBackground] = React.useState(false)

  const onKroll = React.useCallback(() => {
    if (!isClientSide()) {
      return
    }

    const documentTopPosition = document.documentElement.style.top
    const scrollTop = Math.abs(Number.parseInt(documentTopPosition, 10))
    const bodyHasFixedPositionAndHadScrolled = !Number.isNaN(scrollTop)

    if (window.scrollY > 20 || bodyHasFixedPositionAndHadScrolled) {
      setShowBackground(true)
    } else {
      setShowBackground(false)
    }
  }, [])

  React.useEffect(() => {
    if (!isClientSide()) {
      return
    }

    document.addEventListener('scroll', onKroll)

    return () => document.removeEventListener('scroll', onKroll)
  }, [])

  return showBackground
}
