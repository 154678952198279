import React from 'react'
import styled from 'styled-components'
import Logo from './logo.svg'
import {Link} from 'react-router-dom'

type Color = 'black' | 'white'

const logoColors: { [key in Color]: string } = {
  black: '#000000',
  white: '#FFFFFF',
}

interface LogoProps {
  color?: Color
}

export const LogoIcon = (props: LogoProps) => (
  <Link to='/'>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <LogoSvgIcon {...props} />
  </Link>
)

const LogoSvgIcon = styled(Logo)<LogoProps>`
  fill: ${(props) => (props.color === undefined ? logoColors.black : logoColors[props.color])};

  path {
    fill: ${(props) => (props.color === undefined ? logoColors.black : logoColors[props.color])};
  }
`
